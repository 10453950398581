import Image from 'next/image';

type Size = {
  small: string;
  smallPdp: string;
  medium: string;
  large: string;
};

interface QuantityButtonProps {
  qty: number;
  handleDeleteProduct: () => void;
  handleAddProduct: () => void;
  size?: keyof Size;
  page?: 'menu' | 'cart' | 'pdp';
  disabled?: boolean;
}

const heightSizes: Size = {
  small: 'h-[24px]',
  smallPdp: 'h-[28px]',
  medium: 'h-[36px] md:h-[38px]',
  large: 'h-10 md:h-[54px]',
};

const QuantityButton: React.FC<QuantityButtonProps> = ({
  qty,
  handleDeleteProduct,
  handleAddProduct,
  size = 'small',
  page = 'menu',
  disabled,
}: QuantityButtonProps) => {
  const height = heightSizes[size] || heightSizes.small;
  const border = page === 'cart' ? 'border-l border-r px-2.5' : '';
  const imgSize = page === 'menu' ? 16 : 20;
  const style = page === 'cart' ? 'w-[88px]' : 'w-full';
  const icon = page === 'cart' ? 'black-' : '';
  const padding = page === 'cart' ? '' : 'px-4';

  let className = 'w-full text-white bg-black rounded-b-xl';
  switch (page) {
    case 'menu':
      className = 'w-full text-white bg-black rounded-b-xl';
      break;
    case 'cart':
      className = 'text-black border border-grey-disable rounded';
      break;
    case 'pdp':
      className = 'w-full md:w-[300px] text-white bg-black rounded-[48px]';
      break;
    default:
      break;
  }

  return (
    <div className={`flex ${height} ${className}`}>
      <div
        className={`relative flex items-center justify-center py-1 ${style}`}
      >
        <button
          className={`absolute left-1 h-full ${padding} text-xl`}
          onClick={handleDeleteProduct}
        >
          <Image
            src={`/assets/icons/${icon}remove.svg`}
            alt="arrow"
            width={imgSize}
            height={imgSize}
            priority
          />
        </button>
        <span className={`font-interMedium text-sm md:text-base ${border}`}>
          {qty}
        </span>
        <button
          className={`absolute right-1 h-full ${padding} text-xl`}
          onClick={handleAddProduct}
          disabled={disabled}
        >
          <Image
            src={`/assets/icons/${icon}add.svg`}
            alt="arrow"
            width={imgSize}
            height={imgSize}
            priority
          />
        </button>
      </div>
    </div>
  );
};

export default QuantityButton;
