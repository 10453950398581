import React from 'react';

export type ColorType = {
  hex: string;
};

export type TagType = {
  text: string;
  fontColor: ColorType;
  backgroundColor: ColorType;
};

export type PrimaryDisplayTagType = {
  tag: TagType;
  page?: 'menu' | 'pdp' | 'cart' | 'pdp-recommendation';
};

export const PrimaryDisplayTag: React.FC<PrimaryDisplayTagType> = ({
  tag,
  page = 'menu',
}: PrimaryDisplayTagType) => {
  let sizeClass = 'font-interMedium lg:text-base lg:leading-none lg:py-1';
  switch (page) {
    case 'pdp':
      sizeClass = 'font-interMedium py-1 text-sm lg:text-lg';
      break;
    case 'pdp-recommendation':
      sizeClass = 'font-inter text-sm ';
      break;
    case 'cart':
      sizeClass = 'font-interSemibold text-xxs';
      break;
    default:
      break;
  }
  const style =
    page === 'cart' || page === 'pdp-recommendation'
      ? 'static w-fit h-5 mb-1 justify-center'
      : 'absolute';

  return (
    <div
      className={`${style} left-1 top-1 z-10 flex items-center rounded-full lg:left-2 lg:top-2`}
      style={{ backgroundColor: tag?.backgroundColor?.hex }}
    >
      <div
        className={`px-3 text-xs leading-4 ${sizeClass}`}
        style={{ color: tag?.fontColor?.hex }}
      >
        {tag.text}
      </div>
    </div>
  );
};
