import React from 'react';

interface ButtonProps {
  disabled?: boolean;
  onClick?: () => void;
  size?: string;
  text?: string;
}

const baseStyle =
  'text-white cursor-pointer text-base font-inter flex items-center justify-center';

const AddButton: React.FC<ButtonProps> = ({
  disabled = false,
  onClick,
  size = '',
  text = 'Add to cart',
  ...rest
}: ButtonProps) => {
  let width = 'w-full h-9 md:h-[38px] rounded-b-xl';
  if (size === 'small') {
    width = 'h-7 rounded-full px-2';
  }
  const buttonClass = `${baseStyle} ${width} ${disabled ? ' bg-grey-disable' : 'bg-primary'}`;
  const textStyle = size === 'small' ? 'text-sm' : 'text-sm md:text-base';
  return (
    <button
      type="button"
      className={buttonClass}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      <span className={`font-interSemibold ${textStyle}`}>{text}</span>
    </button>
  );
};

export default AddButton;
