export type ColorType = {
  hex: string;
};
export type SecondaryTagType = {
  text: string;
  fontColor: ColorType;
  backgroundColor: ColorType;
  fontFamily?: string;
  fontWeight?: string;
};
export type SecondaryTagProps = {
  tag: SecondaryTagType;
  theme?: 'menu' | 'pdp' | 'recommendation';
  fullWidth?: boolean;
  textSize?: string;
};

type Themes = {
  menu: string;
  pdp: string;
  recommendation: string;
};

const themes: Themes = {
  menu: 'absolute bottom-0 right-0 flex justify-center items-center h-6',
  pdp: ' flex items-center justify-center items-center h-8 md:h-12',
  recommendation:
    'absolute bottom-0 right-0 flex justify-center items-center h-6',
};

export const SecondaryTag: React.FC<SecondaryTagProps> = ({
  tag: { text, fontColor, backgroundColor, fontFamily, fontWeight },
  theme = 'menu',
  fullWidth = false,
  textSize: textSizeOverride,
}) => {
  const bgColor = backgroundColor?.hex || '#848484';
  const textColor = fontColor?.hex || '#FFFFFF';
  const themeClass = themes[theme];
  const width = fullWidth ? 'w-full ' : 'w-1/2 ';
  const textSize =
    textSizeOverride ??
    (theme === 'pdp' ? 'text-sm lg:text-xl' : 'text-xxs lg:text-sm');
  const radius = () => {
    if (theme === 'recommendation') {
      if (!fullWidth) {
        return ' rounded-br-lg';
      }
      if (fullWidth) {
        return ' rounded-b-lg';
      }
    }
    return '';
  };
  return (
    <div
      className={width + themeClass + radius()}
      style={{ backgroundColor: bgColor }}
    >
      <div
        className={`whitespace-nowrap px-2 font-interBold leading-3 lg:px-3 ${textSize}`}
        style={{ color: textColor, fontFamily, fontWeight }}
      >
        {text}
      </div>
    </div>
  );
};
